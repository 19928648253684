import React from "react";
import {
  Heading,
  Image,
  Link,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

import Paragraph from "../ui/common/Paragraph";
import Section from "../ui/common/Section";

const items = [
  {
    title: "",
    text: (
      <Heading size="md">
        <i>
          Submit through our online{" "}
          <Link href="https://portal.micropublication.org" isExternal>
            submission portal
          </Link>
          *! Registration required.
        </i>
      </Heading>
    ),
  },

  {
    title: "Get Your Findings Out to the Scientific Community",
    text: (
      <>
        <Paragraph>
          <i>microPublication Biology</i> is currently accepting submissions
          from the following communities in collaboration with their respective
          knowledgebase:{" "}
        </Paragraph>
        <UnorderedList>
          <ListItem>Caenorhabditis in collaboration with WormBase</ListItem>
          <ListItem>Drosophila in collaboration with FlyBase</ListItem>
          <ListItem>Xenopus in collaboration with Xenbase</ListItem>
          <ListItem>Arabidopsis in collaboration with TAIR</ListItem>
          <ListItem>S. cerevisiae in collaboration with SGD</ListItem>
          <ListItem>S. pombe in collaboration with PomBase</ListItem>
          <ListItem>Zebrafish in collaboration with ZFIN</ListItem>
          <ListItem>Dictyostelium in collaboration with dictyBase</ListItem>
          <ListItem>Maize in collaboration with MaizeGDB</ListItem>
          <ListItem>Mouse in collaboration with MGI</ListItem>
          <ListItem>Tomato in collaboration with Sol Genomics</ListItem>
        </UnorderedList>
        <Paragraph>
          <i>
            Please visit our{" "}
            <GatsbyLink to="/about/for-authors">author guidelines</GatsbyLink>
            page for instructions. If you have trouble with our online system,
            you can download an article template and submit it to us at{" "}
            <Link href="mailto:contact@micropublication.org">
              contact@micropublication.org
            </Link>
            .
          </i>
        </Paragraph>
        <Paragraph>
          If we don’t currently take submissions from your community, do not
          despair. <i>microPublication Biology</i> is only able to publish
          research from communities with a committed interest in our journal and
          a knowledgebase/database that is willing to collaborate with our
          journal editors. If you are interested please contact us at{" "}
          <Link href="mailto:contact@micropublication.org">
            contact@micropublication.org
          </Link>
        </Paragraph>
      </>
    ),
  },
  {
    title: "Integrations",
    text: (
      <>
        <Paragraph>
          microPublication articles are supposed to be atomized data, from one
          experiment. However we know that a larger story can be lurking
          somewhere. Integrations articles allow you to put these data together
          to expand the narrative into a more cohesive report. These should
          still be short (1-3 pages) but can include a final experiment that
          ties everything together. Click here to submit.
        </Paragraph>
        <Paragraph>Example of Integrations microPublications:</Paragraph>
        <UnorderedList>
          <ListItem>
            Minor, P.J. and Sternberg, P.W. (2019). LRP-2 control the
            localization of C. elegans SYS-1/beta-catenin. microPublication
            Biology.
            <Link
              href="https://doi.org/10.17912/micropub.biology.000151"
              isExternal
            >
              10.17912/micropub.biology.000151
            </Link>
          </ListItem>
          <ListItem>
            Minor, P.J. and Sternberg, P.W. (2019). LRP-2 functions in vulval
            precursor cell polarity. microPublication Biology.
            <Link
              href="https://doi.org/10.17912/micropub.biology.000152"
              isExternal
            >
              10.17912/micropub.biology.000152
            </Link>
          </ListItem>
          <ListItem>
            Minor, P.J. and Sternberg, P.W. (2019). LRP-2 likely acts downstream
            of EGL-20/Wnt. microPublication Biology.
            <Link
              href="https://doi.org/10.17912/micropub.biology.000153"
              isExternal
            >
              10.17912/micropub.biology.000153
            </Link>
          </ListItem>
          <ListItem>
            Minor, P.J. and Sternberg, P.W. (2019). Low density lipoprotein
            receptors LRP-1 and LRP-2 in C. elegans. microPublication Biology.
            <Link
              href="https://doi.org/10.17912/micropub.biology.000154"
              isExternal
            >
              10.17912/micropub.biology.000154
            </Link>
          </ListItem>
          <ListItem>
            Minor, PJ; Sternberg, PW (2019). The role of lrp-2 in C. elegans
            vulval cell lineage polarity. microPublication Biology.
            <Link
              href="https://doi.org/10.17912/micropub.biology.000155"
              isExternal
            >
              10.17912/micropub.biology.000155
            </Link>
          </ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    title: "",
    text: (
      <Paragraph>
        Become a{" "}
        <GatsbyLink to="/about/for-reviewers">
          <i>Reviewer</i>
        </GatsbyLink>
        , sign up{" "}
        <Link
          href="https://docs.google.com/forms/d/1mxWT8WFza4-PHcrN6gtzoo4q6jtXshsTwQ2Zgk9jUIA/edit"
          isExternal
        >
          here
        </Link>
        .
      </Paragraph>
    ),
  },
  {
    title: "",

    text: (
      <>
        <Paragraph>
          *Our Submission Portal was developed in collaboration with the{" "}
          <Link href="https://coko.foundation/" isExternal>
            Collaborative Knowledge Foundation
            <Image src="static/images/coko.svg" alt="Coko" width="200px" />
          </Link>
        </Paragraph>
      </>
    ),
  },
];

const SubmitSection = (props) => {
  const { submitRef, isPage } = props;

  return (
    <Section
      items={items}
      title="Submit A New microPublication"
      sectionRef={submitRef}
      isPage={isPage}
    />
  );
};

export default SubmitSection;
