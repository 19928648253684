import React from "react";

import Layout from "../components/Layout";
import SubmitSection from "../content/SubmitSection";

const SubmitNew = (props) => {
  return (
    <Layout>
      <SubmitSection isPage />
    </Layout>
  );
};

export default SubmitNew;
